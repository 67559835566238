import Swal from 'sweetalert2'
export default ({ store, route, $moment }, inject) => {
  inject('util', {
    numberFormat(n, f) {
      if (n === '') {
        return ''
      } else if (n === 0 || isNaN(n) || n === null || n === undefined) {
        return f === undefined ? '0' : parseFloat('0').toFixed(f)
      }

      if (f === undefined) {
        return (parseFloat(n) + '').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
      return parseFloat(n)
        .toFixed(f)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    },
    numberFormatMega(n, f = 1) {
      if (n === '') {
        return ''
      } else if (n === 0 || isNaN(n) || n === null || n === undefined) {
        return f === undefined ? '0' : parseFloat('0').toFixed(f)
      } else if (n !== 0 && n < 1000000) {
        return parseFloat(n / 1000000)
          .toFixed(f)
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else if (n !== 0 && n >= 1000000 && n % 1000000 !== 0) {
        return parseFloat(n / 1000000)
          .toFixed(f)
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else if (n !== 0 && n >= 1000000 && n % 1000000 === 0) {
        return parseFloat(n / 1000000)
          .toFixed(f)
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else {
        return parseFloat(n / 1000000)
          .toFixed(f)
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
    },
    lengthWithoutTags(value) {
      const inputString = value
      // Remove HTML tags using regex
      const cleanString = inputString.replace(/<\/?[^>]+(>|$)/g, '')
      // Calculate the length of the clean string
      const lengthWithoutTags = cleanString.length
      return lengthWithoutTags
    },
    showSwalModal({
      title = null,
      text = null,
      timer = null,
      showConfirm = null,
      icon = null,
      showCancel = null,
      confirmButtonText = null,
      cancelButtonText = null,
      confirmButtonClasses = null,
      cancelButtonClasses = null,
      reverseButtons = null,
      func = () => {},
      submitFunc = () => {},
      cancelFunc = () => {}
    }) {
      Swal.fire({
        icon: icon ?? 'success',
        title: title ?? 'บันทึกข้อมูลสำเร็จ',
        showCancelButton: showCancel ?? false,
        showConfirmButton: showConfirm ?? false,
        confirmButtonText: confirmButtonText ?? 'ตกลง',
        cancelButtonText: cancelButtonText ?? 'ยกเลิก',
        reverseButtons: reverseButtons ?? false,
        text: text ?? null,
        timer: timer ?? null,
        width: 396,
        customClass: {
          icon: 'modal-icon',
          container: 'modal-container',
          popup: 'modal-popup',
          title: 'modal-confirm-title',
          confirmButton: confirmButtonClasses ?? 'btn-secondary',
          cancelButton: cancelButtonClasses ?? 'btn-error'
        }
      }).then((result) => {
        if (result.isConfirmed && showConfirm) {
          submitFunc()
        } else if (result.dismiss === Swal.DismissReason.cancel && showConfirm) {
          cancelFunc()
        } else {
          func()
        }
      })
    }
  })
}
