
import SideBar from '~/components/SideBar.vue'
export default {
  name: 'DefaultLayout',
  components: {
    SideBar
  },
  data() {
    return {
      drawer: true,
      miniVariant: false,
      direction: 'bottom',
      fab: false,
      hover: true,
      user_level: '',
      transition: 'scale-transition',
      avatar: '',
      provinceItems: [],
      // province: [],
      selectedProvince: { id: null, name: null },
      // items: [],
      isLoading: false,
      renderComponent: true,
      websiteBASE: process.env.API_BASE_URL
    }
  },
  computed: {
    isShow: {
      get() {
        return this.$store.state.alert.isShow
      },
      set(val) {
        this.$store.commit('alert/SET_IS_SHOW', val)
      }
    },
    msg: {
      get() {
        return this.$store.state.alert.msg
      },
      set(val) {
        this.$store.commit('alert/SET_MSG', val)
      }
    },
    title: {
      get() {
        return this.$store.state.alert.title
      },
      set(val) {
        this.$store.commit('alert/SET_TITLE', val)
      }
    },
    loading: {
      get() {
        return this.$store.state.alert.loading
      },
      set(val) {
        this.$store.commit('alert/SET_LOADING', val)
      }
    },
    successDialog: {
      get() {
        return this.$store.state.alert.successDialog
      },
      set(val) {
        this.$store.commit('alert/SET_SUCCESS_DIALOG', val)
      }
    }
  },
  watch: {
    successDialog(val) {
      if (val) {
        setTimeout(() => {
          this.successDialog = false
        }, 2000)
      }
    }
  },
  mounted() {
    if (this.$auth.user.user_level === 1) {
      this.user_level = 'Member'
    } else if (this.$auth.user.user_level === 2) {
      this.user_level = 'Admin'
    } else if (this.$auth.user.user_level === 3) {
      this.user_level = 'Master'
    } else if (this.$auth.user.user_level === 99) {
      this.user_level = 'Super Admin'
    }
    if (this.$auth.loggedIn) {
      this.avatar = this.$auth.user.profile_image_path
        ? this.websiteBASE + '/' + this.$auth.user.profile_image_path
        : ''
      const provinces = this.$auth.user.provinces
      if (provinces.length > 0) {
        if (this.$helpers.getProvinceIdByUrl()) {
          this.selectedProvince = provinces.find((element) => element.id === this.$helpers.getProvinceIdByUrl())
        }
        this.provinceItems = this.$auth.user.provinces
      }
    }
  },
  created() {},
  methods: {
    changeProvince(province) {
      this.$router.replace({ params: { province: province.id } })
      this.$store.dispatch('SET_PROVINCE_ID', province.id)
      this.forceRerender()
    },
    async logout() {
      await this.$auth.logout()
      this.$router.push('/auth/login')
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
    }
  }
}
