
export default {
  data() {
    return {
      items: [
        {
          title: 'โครงการใหม่',
          items: [
            {
              id: 1,
              title: 'ใบประกาศโครงการใหม่',
              link: '/posts/projects'
            },
            { id: 8, title: 'โครงการแนะนำ', link: '/boost-posts' },
            {
              id: 12,
              title: 'โครงการเปิดตัวเร็วๆนี้',
              link: '/projects-coming-soon'
            },
            {
              id: 28,
              title: 'วีดีโอรีวิวโครงการ',
              link: '/video-review-new'
            },
            { id: 10, title: 'VR', link: '/boost-vr' },
            {
              id: 21,
              title: 'ข้อมูลเจ้าของโครงการ',
              link: '/customers/companies'
            },
          ]
        },
        {
          title: 'บ้านมือสอง',
          items: [
            {
              id: 3,
              title: 'ใบประกาศบ้านมือสอง',
              link: '/posts/secondhand'
            },
            {
              id: 9,
              title: 'วีดีโอมือสอง',
              link: '/video-review/secondHand'
            },
            {
              id: 31,
              title: 'ใบประกาศนอกน่าอยู่',
              link: '/posts/external'
            },
            {
              id: 27,
              title: 'ข้อมูลผู้ใช้ทั่วไป',
              link: '/customers/normals'
            }
          ]
        },
        {
          title: 'บ้านพร้อม',
          items: [
            {
              id: 39,
              title: 'ข้อมูลเจ้าของทรัพย์',
              link: '/baanprompt/property-owner'
            },
            { id: 20, title: 'ข้อมูลนายหน้า', link: '/baanprompt/agents' },
            { id: 39, title: 'รีเฟอร์ขาเข้า', link: '/baanprompt/refers-in' },
            { id: 39, title: 'รีเฟอร์ขาออก', link: '/baanprompt/refers-out' }
          ]
        },
        {
          title: 'หอพัก/อพาร์ทเมนท์',
          items: [
            {
              id: 7,
              title: 'ใบประกาศหอพัก/อพาร์ท...',
              link: '/posts/apartment'
            }
          ]
        },
        {
          title: 'รับสร้างบ้าน',
          items: [
            {
              id: 43,
              title: 'ใบประกาศแบบบ้าน',
              link: '/posts/home-builder'
            },
            {
              id: 49,
              title: 'บริษัทรับสร้างบ้านชั้นนำ',
              link: '/customers/companies-home-builder/leading-company'
            },
            {
              id: 45,
              title: 'โปรไฟล์บริษัทพรีเมี่ยม',
              link: '/customers/companies-home-builder/premium'
            },
            {
              id: 46,
              title: 'แบบบ้านพรีเมี่ยม',
              link: '/posts/home-builder/premium'
            },
            {
              id: 48,
              title: 'VR 360',
              link: '/customers/companies-home-builder/vr'
            },
            {
              id: 44,
              title: 'Top Ads',
              link: '/customers/companies-home-builder/top-ads'
            },
            {
              id: 22,
              title: 'ข้อมูลบริษัทรับสร้างบ้าน',
              link: '/customers/companies-home-builder'
            },
            {
              id: 22,
              title: 'เอกสารและการรับรอง',
              link: '/customers/companies-home-builder/important-file'
            },
          ]
        },
        {
          title: 'จัดการ Homepage',
          items: [
            {
              id: 14,
              title: 'Pop-Up',
              link: '/manage-home-page/pop-up'
            },
            {
              id: 37,
              title: 'Pop-Up แบบติดตาม',
              link: '/manage-home-page/communication-pop-up'
            },
            {
              id: 33,
              title: 'แถบข้อความสื่อสาร',
              link: '/manage-home-page/communication-bar'
            },
            {
              id: 2,
              title: 'โปรโมชั่นน่าอยู่',
              link: '/posts/projects/promo-inhouse'
            }
          ]
        },
        {
          title: 'จัดการ Event',
          items: [
            {
              id: 40,
              title: 'Event Online',
              link: '/manage-event-online'
            }
          ]
        },
        {
          title: 'จัดการงานลูกค้าอื่นๆ',
          items: [
            {
              id: 25,
              title: 'Top Ads',
              link: '/top-ads'
            },
            { id: 11, title: 'แบรนด์โครงการ', link: '/boost-company' },
            {
              id: 30,
              title: 'จัดการ Ads เจ้าของโครงการ',
              link: '/manage-other-page/boost-company-ads'
            },
            {
              id: 29,
              title: 'หน้าฟอร์มลงทะเบียน',
              link: '/manage-other-page/enrolls/interest'
            }
          ]
        },
        {
          title: 'จัดการแบนเนอร์',
          items: [
            { id: 16, title: 'Banner Hero', link: '/banners/1' },
            { id: 16, title: 'Banner A', link: '/banners/2' },
            { id: 16, title: 'Banner B', link: '/banners/3' },
            { id: 16, title: 'Banner C', link: '/banners/4' }
          ]
        },
        {
          title: 'โปรน่าอยู่',
          items: [
            { id: 52, title: 'Promotions Walk-in', link: '/promotions-walk-in' },
            { id: 53, title: 'โปรจอง', link: '/booking-promotions' },
          ]
        },
        {
          title: 'จัดการอื่นๆ',
          items: [
            {
              id: 38,
              title: 'ไกด์ไลน์',
              link: '/guideline'
            },
            {
              id: 35,
              title: 'จัดการ short url',
              link: '/short-url'
            },
            {
              id: 36,
              title: 'QR Code',
              link: '/qrcodes'
            },
            {
              id: 41,
              title: 'บรอดแคสต์แคมเปญ',
              link: '/line-broadcast/broadcast-campaign'
            },
            {
              id: 42,
              title: 'แดชบอร์ดแคมเปญ',
              link: '/line-broadcast/dashboard-campaign'
            },
            {
              id: 50,
              title: 'รายชื่อคนคำนวณสินเชื่อ',
              link: '/loan'
            }
          ]
        },
        {
          title: 'แผนที่',
          items: [
            { id: 26, title: 'แสดงข้อมูลใบประกาศในแผนที่', link: '/maps' }
          ]
        },
        {
          title: 'รายชื่อผู้สนใจใบประกาศ',
          items: [
            { id: 51, title: 'โครงการใหม่', link: '/list-register-interest/project' },
            { id: 51, title: 'มือสอง', link: '/list-register-interest/secondhand' },
            { id: 51, title: 'แบบบ้าน', link: '/list-register-interest/home-builder-post' },
            { id: 51, title: 'บริษัทรับสร้างบ้าน', link: '/list-register-interest/home-builder-company' }
          ]
        }
      ],
      province: null
    }
  },
  mounted() {
    // console.log('sidebar')
  },
  methods: {
    toPage(path) {
      if (this.$helpers.getProvinceIdByUrl()) {
        // return '/dashboard/' + this.$route.params.province + path
        this.$router.push(
          `/dashboard/${this.$helpers.getProvinceIdByUrl()}${path}`
        )
      }
    },
    showMenu(menuId) {
      if (this.$auth.user?.provinces?.length === 0) {
        return false
      }
      const province = this.$auth.user?.provinces?.find(
        (element) => element.id === this.$helpers.getProvinceIdByUrl()
      )
      if (province === undefined) {
        return false
      } else {
        const found = province.permissions.find(
          (el) => el.permission_id === menuId
        )
        // console.log(found)
        if (found) {
          return true
        } else {
          return false
        }
      }
      // console.log(province, this.$route.params.province)
    },
    active(val) {
      const pathArr = this.$route.fullPath.split('/')
      const currentPath = pathArr.slice(3).join('/')
      if (pathArr.length >= 4 && val === `/${currentPath}`) {
        return true
      }
      return false
    }
  }
}
