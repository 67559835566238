export const mutations = {
  setProvinceId(state, provinceId) {
    state.provinceId = provinceId
  }
}
export const actions = {
  SET_PROVINCE_ID({ commit }, provinceId) {
    commit('setProvinceId', provinceId)
  }
}
export const state = () => ({
  provinceId: null,
  provinceName: null
})
export const getters = {
  isSuperAdmin(state) {
    if (state.auth.loggedIn) {
      return state.auth.user.user_level === 99
    }
    return false
  },
  isAdmin(state) {
    if (state.auth.loggedIn) {
      return state.auth.user.user_level === 2
    }
    return false
  },
  isMember(state) {
    if (state.auth.loggedIn) {
      return state.auth.user.user_level === 1
    }
    return false
  },
  provinceId(state) {
    return state.provinceId
  }
}
